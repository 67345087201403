import { TipsAndUpdates } from "common/Components/TipsAndUpdates/TipsAndUpdates";
import React from "react";
import { Tabs } from "@my-scoot/component-library-legacy";
import {
  EXLY_CONNECT_LEARN_MORE_HREFS,
  EXLY_CONNECT_TABS,
  EXLY_CONNECT_TITLE,
} from "features/ExlyConnect/ExlyConnect.constants";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import ExlyTable from "common/Components/ExlyTable";
import { ExlyConnectMeetingCard } from "features/ExlyConnect/modules/ExlyConnectMeetingCard";
import {
  getFilterConfig,
  tableChipsFormatter,
} from "./OndemandExlyConnect.tableConfig";
import { Header } from "common/Components/Header";
import { onTabChange } from "features/ExlyConnect/ExlyConnect.utils";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import ExlyEmptyState from "features/Common/modules/ExlyEmptyState/ExlyEmptyState";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";
import { useCheckUserValidity } from "features/ExlyConnect/useCheckUserValidity";
import EmptyListIcon from "assets/vectors/infoModal/search.svg";

export const OndemandExlyConnect = withComponentLibraryTheme((props) => {
  const isDesktop = useIsDesktop();

  const { isUserValid, handleCheckUserValidity, errorMessage } =
    useCheckUserValidity();

  React.useEffect(() => {
    handleCheckUserValidity();
  }, []);

  return (
    <>
      <Header
        title={EXLY_CONNECT_TITLE}
        classNames={{
          container: `px-4 `,
        }}
        right={
          <LearnMoreCta href={EXLY_CONNECT_LEARN_MORE_HREFS.EXLY_CONNECT} />
        }
      />
      <div className={`p-4`}>
        <TipsAndUpdates
          className="mb-4 small"
          iconSize="18px"
          tip={
            <span>
              It is advised to launch the meeting 5 minutes early for all the
              participants to join prior.
            </span>
          }
        />

        <div className={`mb-4`}>
          <Tabs
            tabs={Object.values(EXLY_CONNECT_TABS)}
            value={EXLY_CONNECT_TABS.on_demand.value}
            onChange={onTabChange}
            tabVariant="outlined"
          />
        </div>

        <ExlyTable
          ra_props={{
            ...props,
          }}
          noExportButton
          tableFilters={getFilterConfig()}
          tableChipsFormatter={tableChipsFormatter()}
          layoutProps={{
            showFixedBars: true,
            noShadow: true,
            paddingBottom: "32px",
            paddingMobile: "0px",
          }}
          primaryKey="id"
          gridView
          gridWidth={{ desktop: "100%", mobile: "100%" }}
          GridComponent={({ record }) =>
            ExlyConnectMeetingCard({
              data: record,
              hideTime: true,
              hideDate: true,
              hideBroadcastMeetingLink: true,
              isUserValid,
              invalidUserError: errorMessage,
            })
          }
          exlyFilteredEmptyStateProps={
            isDesktop && {
              secondaryText: (
                <>
                  <ExlyEmptyState
                    description="Try different keywords"
                    secondaryCtaProps={{
                      variant:
                        EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
                      learnMoreHref: EXLY_CONNECT_LEARN_MORE_HREFS.EXLY_CONNECT,
                    }}
                  />
                </>
              ),
            }
          }
          emptyStateProps={{
            version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
            title: "No results found",
            description:
              "When you create a new session using Exly Zoom it will show here. ",
            imgSrc: EmptyListIcon,
            secondaryCtaProps: {
              variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
              learnMoreHref: EXLY_CONNECT_LEARN_MORE_HREFS.EXLY_CONNECT,
            },
            className: "mt-4",
          }}
        />
      </div>
    </>
  );
});
